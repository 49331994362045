import { template as template_b2ec8aa045f0454e94f3c8fd0aea8940 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_b2ec8aa045f0454e94f3c8fd0aea8940(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
