import { template as template_b5f52a0a82b140d7828d2c8328bed79a } from "@ember/template-compiler";
const FKLabel = template_b5f52a0a82b140d7828d2c8328bed79a(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
