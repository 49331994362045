import { template as template_b46b32fc65214b3f9baa5754002719bf } from "@ember/template-compiler";
const FKControlMenuContainer = template_b46b32fc65214b3f9baa5754002719bf(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
